import { cn } from "@/lib/utils";
import { useState } from "react";
import Logo from "@/icons/Logo";

type imageState = "loading" | "ok" | "fallback";

function Avatar({
    className,
    alt = "dpj support agent",
    src,
}: {
    className?: string;
    alt?: string;
    src?: string;
}) {
    const [imageState, imageState_set] = useState("loading" as imageState);
    if (imageState === "loading" && !src) {
        imageState_set("fallback");
    }

    return (
        <div
            className={cn("avatar tw-h-14 tw-w-14", className)}
            data-state={imageState}
        >
            <div className="tw-flex tw-h-full tw-w-full tw-rounded-full">
                <span
                    className={cn(
                        "tw-inline-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-bg-white",
                        {
                            "tw-hidden":
                                imageState !== "fallback" &&
                                imageState !== "loading",
                        },
                    )}
                >
                    <span className="tw-text-lg tw-font-medium tw-leading-none">
                        <Logo sizePx={35} />
                    </span>
                </span>
                <img
                    onLoad={() => {
                        // console.log("onLoad", imageState);
                        if (imageState === "loading") {
                            imageState_set("ok");
                        }
                    }}
                    onError={() => {
                        // console.log("onError", imageState);
                        if (imageState === "loading") {
                            imageState_set("fallback");
                        }
                    }}
                    alt={alt}
                    src={src}
                    className={cn(
                        "tw-h-full tw-w-full tw-rounded-full tw-object-cover tw-object-center",
                        {
                            "tw-hidden": imageState !== "ok",
                        },
                    )}
                />
            </div>
        </div>
    );
}

export { Avatar };
