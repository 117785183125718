import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import { useGlobalStore } from "./state/globalStore";
import StartChatOptionalParams from "@microsoft/omnichannel-chat-sdk/lib/core/StartChatOptionalParams";
import IMessage from "@microsoft/omnichannel-ic3core/lib/model/IMessage";
import OmnichannelMessage from "@microsoft/omnichannel-chat-sdk/lib/core/messaging/OmnichannelMessage";
import ChatSDKMessage from "@microsoft/omnichannel-chat-sdk/lib/core/messaging/ChatSDKMessage";
import { Message, CustomContext, CartProduct } from "./types";
import * as Sentry from "@sentry/react";
import { chatScrollToEnd } from "./utils";

const act = {
    connect(chatSDK: OmnichannelChatSDK): Promise<void> {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "connect",
            level: "debug",
        });
        const replay = Sentry.getReplay()
        if (replay) {
            // replay.startBuffering() //TODO: don't record every session
            replay.flush()
        }
        // if (replay && Date.now() + 13 % 5) {
        // }
        return new Promise((resolve, reject) => {
            if (
                useGlobalStore.getState().connection.status !== "Down" &&
                useGlobalStore.getState().connection.status !== "DownWithError"
            ) {
                console.log("[act] connect: connection not down, skipping \"connect\"");
                resolve();
                Sentry.addBreadcrumb({
                    category: "chatSDK",
                    message: "connect skipped because connection is not down",
                    data: useGlobalStore.getState().connection,
                    level: "info",
                });
            }
            useGlobalStore.setState(() => ({ connection: { status: "Connecting" } }));
            const state = useGlobalStore.getState();
            let params: StartChatOptionalParams = {
                liveChatContext: state.context ? state.context : undefined,
                isProactiveChat: false,
                preChatResponse: { initial_url: window.location.pathname, proactiveMessage: "<not proactive>" },
                sendDefaultInitContext: true,
                customContext: contextProvider()
            };


            if (state.currentProactive && state.currentProactive.type === "chat") {
                params = {
                    ...params,
                    isProactiveChat: true,
                    preChatResponse: {
                        ...params.preChatResponse,
                        proactiveMessage: state.currentProactive.inChatMessage
                    }
                };
            }
            Sentry.addBreadcrumb({
                category: "chatSDK",
                message: ".startChat",
                level: "debug",
            });
            return chatSDK
                .startChat(params)
                .then(() => {
                    useGlobalStore.setState(() => ({ connection: { status: "Up" } }));
                    setListeners(chatSDK);
                    Sentry.addBreadcrumb({
                        category: "sdkAction",
                        message: "connected",
                        data: useGlobalStore.getState().connection,
                        level: "debug",
                    });
                })
                .catch((err) => {
                    console.error("[sdkWrangler]: connection failed", err);
                    state.set_connection({ status: "DownWithError", reason: "failed to connect" });
                    reject(err);
                    Sentry.captureException(err, { level: "error", tags: { DownWithError: "failed to connect" }, extra: { ...err } });
                    // TODO: handle error
                })
                .then(() => {
                    console.log("[sdkWrangler]", "fetching context and messages");
                    Sentry.addBreadcrumb({
                        category: "chatSDK",
                        message: "fetching context and messages",
                        level: "debug",
                    });
                    return Promise.all([
                        fetchMessages(chatSDK),
                        getContext(chatSDK),
                    ]).then(() => {
                        resolve();
                        Sentry.addBreadcrumb({
                            category: "chatSDK",
                            message: "fetched context and messages",
                            level: "debug",
                        });
                    });
                })
                .catch((err) => {
                    console.error(
                        "[sdkWrangler]",
                        "error while fetching context or messages",
                        err,
                    );
                    reject(err);
                    Sentry.captureException(err, { level: "warning", tags: { DownWithError: "failed to fetch context or messages" }, extra: { ...err } });
                    // TODO: handle error
                })
        });
    },
    disconnect(chatSDK: OmnichannelChatSDK) {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "disconnecting",
            level: "info",
        });
        useGlobalStore.setState({ connection: { status: "Closing" } });
        // chatSDK.getPostChatSurveyContext().then(console.log).catch(console.error);
        return chatSDK.endChat()
            .then(() => {
                useGlobalStore.setState({ connection: { status: "Down" } });
                chatSDK.getPostChatSurveyContext().then(console.log).catch(console.error);
                Sentry.addBreadcrumb({
                    category: "chatSDK",
                    message: "disconnected",
                    level: "info",
                });
            })
            .catch((err) => {
                console.error("[sdkWrangler] chat disconnect failed", err);
                // useGlobalStore.getState().act_setError({ type: "EndChat", err: err.toString() });
                Sentry.captureException(err, { level: "warning", extra: { ...err } });
            }).finally(() => {
                useGlobalStore.setState({ ...useGlobalStore.getInitialState(), connection: { status: "Down" } });
            })
    },
    reset: (chatSDK: OmnichannelChatSDK) => {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "reset",
            level: "info",
        });
        const { customer, proactives, now, cart } = useGlobalStore.getState();
        useGlobalStore.setState({ ...useGlobalStore.getInitialState(), customer, proactives, now, cart });

        chatSDK.endChat()
            .then(() => {
                Sentry.addBreadcrumb({
                    category: "chatSDK",
                    message: "reset, ended chat",
                    level: "debug",
                })
            })
            .catch((err) => {
                Sentry.captureException(err, { level: "warning", extra: { ...err } })
            })
            .finally(() => {
                Sentry.addBreadcrumb({
                    category: "chatSDK",
                    message: "reset, resetting state and starting new chat",
                    level: "debug",
                })
                const error = useGlobalStore.getState().error;
                useGlobalStore.setState({ ...useGlobalStore.getInitialState(), open: "chat" });
                const params: StartChatOptionalParams = {
                    isProactiveChat: false,
                    preChatResponse: { initial_url: window.location.pathname, proactiveMessage: "<not proactive>" },
                    sendDefaultInitContext: true,
                    customContext: { ...contextProvider(), experienced_error: error },
                };
                chatSDK.startChat(params)
                    .then(() => {
                        Sentry.addBreadcrumb({
                            category: "chatSDK",
                            message: "reset, started chat",
                            level: "debug",
                        })
                        Promise.resolve();
                    })
            })

    },


    fetchMessages: fetchMessages,
    sendMessage: async (chatSDK: OmnichannelChatSDK) => {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "sending message",
            level: "info",
        });
        const state = useGlobalStore.getState();
        if (state.isSending) {
            console.warn("[sdkWrangler]", "already sending message, skipping");
            Sentry.captureMessage("act.sendMessage called while already sending message");
            return;
        }
        if (state.connection.status !== "Up") {
            console.error("[sdkWrangler]", "connection is not up, skipping");
            Sentry.captureMessage("act.sendMessage called while connection is not up");
            return;
        }
        if (state.input.length === 0) {
            console.error("[sdkWrangler]", "input is empty, skipping");
            Sentry.captureMessage("act.sendMessage called with empty input");
            return;
        }

        const msg: ChatSDKMessage = {
            content: state.input + infoString(),
            metadata: { url: window.location.pathname },
            timestamp: new Date(),
            tags: ["FromCustomer", "NotConfirmed"],
        }

        useGlobalStore.setState({ isSending: true });
        return chatSDK.sendMessage(msg)
            .then(() => {
                Sentry.addBreadcrumb({
                    category: "chatSDK",
                    message: "message sent",
                    level: "info",
                    data: msg,
                });
                useGlobalStore.setState({ input: "", isSending: false });
                fetchMessages(chatSDK);
            })
            .catch((err) => {
                console.error("[sdkWrangler]", "message send failed", err);
                state.act_setError({ type: "SendMessage", err: err.toString(), input: state.input })
                useGlobalStore.setState({ isSending: false, connection: { status: "DownWithError", reason: "failed to send message" } });
                Sentry.captureException(err, { level: "error", tags: { DownWithError: "failed to send message" }, extra: { ...err } });
                return Promise.reject(err);
            })
    },
    setListeners: setListeners,
    sendTypingEvent: sendTypingEvent,
    getContext: getContext,
};




// fetch messages

function fetchMessages(
    chatSDK: OmnichannelChatSDK,
): Promise<void> {
    Sentry.addBreadcrumb({
        category: "chatSDK",
        message: "fetching messages",
        level: "info",
    });
    return chatSDK.getMessages()
        .then((messagesRaw) => {
            Sentry.addBreadcrumb({
                category: "chatSDK",
                message: "messages fetched",
                level: "debug",
                data: { numberOfMessages: messagesRaw?.length },
            });
            if (typeof messagesRaw === "undefined") {
                Sentry.captureMessage("getMessages returned undefined", { level: "warning" });
                useGlobalStore.setState({ messages: [], latestMessageId: null, latestMessageSeenId: null });
                return;
            }
            if (messagesRaw.length === 0) {
                Sentry.captureMessage("getMessages returned empty array", { level: "warning" });
                useGlobalStore.setState({ messages: [], latestMessageId: null, latestMessageSeenId: null });
                return;
            }
            const state = useGlobalStore.getState();
            const messages = messagesRaw.map(toMsg);
            if (state.latestMessageId === messages[0].id) {
                console.log(
                    "[sdkWrangler]",
                    "no new messages ???? TODO: handle this",
                );
                // return;
            }
            if (state.open === "chat") {
                useGlobalStore.setState({ messages, latestMessageId: messages[0].id, latestMessageSeenId: messages[0].id, agentTyping: false });
            } else {
                useGlobalStore.setState({ messages, latestMessageId: messages[0].id, agentTyping: false });
            }
        }).catch(err => {
            console.error(
                "[sdkWrangler]",
                "error in getMessages",
                err,
            );
            Sentry.captureException(err, { level: "error", extra: { ...err } });
            useGlobalStore.setState({ messages: [], latestMessageId: null, latestMessageSeenId: null, agentTyping: false });
            return Promise.reject(err);
        }).finally(() => {
            chatScrollToEnd();
        })
}

async function getContext(chatSDK: OmnichannelChatSDK): Promise<void> {
    try {
        const ctx = await chatSDK.getCurrentLiveChatContext();
        if ("chatToken" in ctx) {
            useGlobalStore.setState({ context: ctx });
        } else {
            useGlobalStore.setState({ context: null });
        }
    } catch (error) {
        console.error("[sdkWrangler]", "error in getContext", error);
        return Promise.reject(error);
    }
}
function sendTypingEvent(
    chatSDK: OmnichannelChatSDK,
): void {
    console.log("[sdkWrangler]", "sending typing event");
    chatSDK.sendTypingEvent()
        // .then(() => {
        // console.log("[sdkWrangler]", "typing event sent");
        // })
        .catch((err) => {
            console.error("[sdkWrangler]", "error while sending typing event", err);
            useGlobalStore.getState().act_setError({ type: "SendTypingEvent", err: err.toString() });
        })
}

// listeners
function setListeners(
    chatSDK: OmnichannelChatSDK,
) {
    const agentActiveTimeoutMs = 5000;
    Sentry.addBreadcrumb({
        category: "chatSDK",
        message: "setting listeners",
        level: "debug",
    });
    const proms = [
        chatSDK.onNewMessage(
            onNewMessage(chatSDK),
        ),
        chatSDK.onTypingEvent(
            onAgentTypingEvent(agentActiveTimeoutMs),
        ),
        chatSDK.onAgentEndSession(
            onAgentEndSession(chatSDK),
        ),
    ];
    return Promise.all(proms);
}

function onNewMessage(
    chatSDK: OmnichannelChatSDK,
): (msg: OmnichannelMessage) => void {
    return (msg) => {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "recieved new message",
            level: "debug",
            data: msg,
        });
        // console.log("[sdkWrangler]", "onNewMessage event: ", msg);
        // useGlobalStore.setState((state) => {
        //     const messages = state.messages;
        //     messages.push(toMsg(msg));
        //     return { messages, agentTyping: false };
        // });

        // TODO: handle new message instead of refetching all messages
        // at least show the new message while refetching...
        fetchMessages(chatSDK);
    };
}

//TODO: Consider global state for agent typing status or timeout id.
function onAgentTypingEvent(
    timeoutMs: number,
) {
    let timeout: NodeJS.Timeout | null = null;
    return function () {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "recieved typing event",
            level: "debug",
        });
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            useGlobalStore.setState({ agentTyping: false });
        }, timeoutMs);
        useGlobalStore.setState({ agentTyping: true });
    };
}
function onAgentEndSession(
    chatSDK: OmnichannelChatSDK,
): (message: unknown) => void {
    return (message) => {
        Sentry.addBreadcrumb({
            category: "chatSDK",
            message: "recieved end session event from agent",
            level: "info",
            data: message ? message : "no message",
        });
        const connPre = useGlobalStore.getState().connection;
        switch (connPre.status) {
            case "Up":
                return closeConnection();
            default:
                useGlobalStore.setState({ connection: { status: "Down" }, context: null });
                Sentry.captureMessage("[onAgentEndSession] Unexpected connection status, was \"" + connPre.status + "\" expected \"Up\". Associated message: " + message);
            // useGlobalStore.setState({ connection: { status: "DownWithError", reas   on: "[onAgentEndSession] Unexpected connection status, was \"" + connPre.status + "\" expected \"Up\". Associated message: " + message } });
        }

        function closeConnection() {
            Sentry.addBreadcrumb({
                category: "chatSDK",
                message: "closing session",
                level: "info",
            });
            useGlobalStore.setState({ connection: { status: "Closing" } });
            // console.log("[sdkWrangler]", "session ended", message);
            chatSDK.endChat()
                .then(() => {
                    Sentry.addBreadcrumb({
                        category: "chatSDK",
                        message: "session ended",
                        level: "debug",
                    });
                    useGlobalStore.setState({ connection: { status: "Down" }, context: null });
                })
                .catch((err) => {
                    console.error("[sdkWrangler]", "error while ending chat", err);
                    useGlobalStore.getState().act_setError({ type: "EndChat", err: err.toString() });
                    useGlobalStore.setState({ connection: { status: "DownWithError", reason: `session ended, got error ${err}` } });
                    Sentry.captureException(err, { level: "warning", tags: { DownWithError: `session ended, got error ${err}` } });
                })
        }
    };
}


// string utils
function infoString(): string {
    const splitPath = window.location.pathname.split("/");
    let pathString = splitPath.slice(1, 4).join("/");
    if (splitPath.length > 3) {
        pathString += "/..";
    }
    return `\n<info>\n<info>/${pathString}`;
}

function prodToString(p: CartProduct, i: number): string {
    let str = "";
    if (i !== 0) {
        str += ", ";
    }
    str += `${p.name} (${p.quantity})`;
    return str;
}

// Context stuff
function contextProvider(): CustomContext {
    const state = useGlobalStore.getState();
    let proactiveMessage = "<not proactive>";
    if (state.currentProactive && state.currentProactive.type === "chat") {
        proactiveMessage = state.currentProactive.inChatMessage;
    }
    const ctx = {
        'proactive_message': { 'value': proactiveMessage, 'isDisplayable': true },
        'initial_path': { 'value': window.location.pathname, 'isDisplayable': true },
        'cart_total_ex': { 'value': useGlobalStore.getState().cart.total, 'isDisplayable': true },
        'cart_products': { 'value': "" + useGlobalStore.getState().cart.products.map(prodToString), 'isDisplayable': true },
    };
    Sentry.addBreadcrumb({
        category: "chatSDK",
        message: "contextProvider called",
        level: "debug",
        data: ctx,
    });
    return ctx;
}



// Transforming messages
function toMsg(raw: IMessage | OmnichannelMessage): Message {
    let id = "N/A";
    let liveChatVersion = 2;
    let contentType = "Text";

    if ("id" in raw) {
        id = raw.id;
    }
    if ("liveChatVersion" in raw) {
        liveChatVersion = raw.liveChatVersion;
    }
    if ("contentType" in raw) {
        contentType = raw.contentType as string; //Content Type is Text or RichText in IMessage
    }

    const msg: Message = {
        ...raw,
        id,
        liveChatVersion,
        contentType,
    };
    return msg;
}



export default act;


/**
 * These are the methods that are available on chatSDK
 */
// chatSDK.startChat,
// chatSDK.endChat,
// chatSDK.createChatAdapter,
// chatSDK.downloadFileAttachment,
// chatSDK.emailLiveChatTranscript,
// chatSDK.getAgentAvailability,
// chatSDK.getCallingToken,
// chatSDK.getChatReconnectContext,
// chatSDK.getChatToken,
// chatSDK.getConversationDetails,
// chatSDK.getCurrentLiveChatContext,
// chatSDK.getDataMaskingRules,
// chatSDK.getLiveChatConfig,
// chatSDK.getLiveChatTranscript,
// chatSDK.getMessages,
// chatSDK.getPostChatSurveyContext,
// chatSDK.getPreChatSurvey,
// chatSDK.getVoiceVideoCalling,
// chatSDK.isVoiceVideoCallingEnabled,
// chatSDK.onAgentEndSession,
// chatSDK.onNewMessage,
// chatSDK.onTypingEvent,
// chatSDK.sendTypingEvent,
// chatSDK.uploadFileAttachment,
// chatSDK.setDebug,
