import { StateCreator } from 'zustand'
import { CartProduct } from "@/lib/types"
import { CartRaw } from '../prestashop';
import * as Sentry from "@sentry/react";


interface CartStateSlice {
    cart: {
        latestUpdate: number;
        products: Array<CartProduct>;
        total: string;
    };
    cartTryUpdateFromPrestashop: () => void;
    // cartOnTick: () => void;
}

const createCartStateSlice: StateCreator<
    CartStateSlice,
    [],
    [],
    CartStateSlice> = (set) => (
        {
            cart: {
                latestUpdate: 0,
                products: [],
                total: "<not set>",
            },
            // cartOnTick: () => {
                // const recheckFrequency = 10_000;
                // if ((recheckFrequency) < (Date.now() - get().cart.latestUpdate)) {
                    // get().cartTryUpdateFromPrestashop();
                // }
            // },
            cartTryUpdateFromPrestashop: () => {
                Sentry.addBreadcrumb({
                    category: "CartStateSlice",
                    message: "cartTryUpdateFromPrestashop called",
                    level: "debug",
                });

                let cartRaw = (window as { prestashop?: { cart?: CartRaw } })?.prestashop?.cart;
                // On pages that are cached by page_cache the cart is not in window.prestashop, but in window.prestashop_pc.
                if (!cartRaw) {
                    console.log("cart not found in window.prestashop, trying window.prestashop_pc");
                    Sentry.addBreadcrumb({
                        category: "CartStateSlice",
                        message: "cart not found in window.prestashop, trying window.prestashop_pc",
                        level: "debug",
                        data: { path: window.location.host + window.location.pathname },
                    });
                    cartRaw = (window as { prestashop_pc?: { cart?: CartRaw } })?.prestashop_pc?.cart;
                }

                if (cartRaw) {
                    Sentry.addBreadcrumb({
                        category: "CartStateSlice",
                        message: "cart found on window.prestashop (or window.prestashop_pc)",
                        level: "debug",
                        data: cartRaw,
                    });
                    const newCart = {
                        latestUpdate: Date.now(),
                        products: cartRaw.products.map((p) => ({
                            id: p.id,
                            url: p.canonical_url,
                            name: p.name,
                            ref: p.reference_to_display,
                            category: p.category,
                            allow_out_of_stock_purchases: p.allow_oosp === 1,
                            quantity: p.quantity,
                            stock: p.stock_quantity,
                            attributes: p.attributes_small,
                            img: p.image_url,
                            price: p.price,
                            total: p.total,
                            availability: p.availability_message,
                        })),
                        total: cartRaw.totals.total_excluding_tax.value,
                    }
                    set((state) => ({ ...state, cart: newCart }));
                } else {
                    // console.error("No cart found in window.prestashop");
                }
            }
        });


export type { CartStateSlice as CartSlice };
export { createCartStateSlice };
