import { Message } from "./Messages/Message";
import { MessageSkeleton } from "@/components/ui/skeleton";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import { useGlobalStore } from "@/lib/state/globalStore";
import { Message as TMessage } from "@/lib/types";
import { localization as l } from "@/lib/localization";
import { Avatar } from "./ui/avatar";
// import ErrorBoundary from "@/components/errorBoundry";
import * as Sentry from "@sentry/react";
import { chatScrollToEnd, cn } from "@/lib/utils";
import { useEffect, useRef } from "react";
import { useShallow } from "zustand/react/shallow";

type Props = {
    chatSDK: OmnichannelChatSDK;
};

function Messages({ chatSDK }: Props) {
    const connStatus = useGlobalStore((state) => state.connection.status);
    const {
        messages,
        agentTyping,
        sending,
        proactive,
        scrollAtBottom,
        error,
        set_scrollAtBottom,
    } = useGlobalStore(
        useShallow((state) => ({
            messages: state.messages,
            agentTyping: state.agentTyping,
            sending: state.isSending,
            proactive: state.startedFromProactive,
            scrollAtBottom: state.scrollAtBottom,
            error: state.error,
            set_scrollAtBottom: state.set_scrollAtBottom,
        })),
    );
    const indicatorRef = useRef<HTMLDivElement>(null);
    const indicatorRefCurrent = indicatorRef.current;
    const MessageNow = (msg: TMessage, index: number) => (
        <Sentry.ErrorBoundary fallback={<p>Error while rendering message</p>}>
            <Message
                chatSDK={chatSDK}
                msg={msg}
                key={msg.id}
                latest={index === 0}
            />
        </Sentry.ErrorBoundary>
    );

    useEffect(() => {
        // console.log("useEffect1, at bottom?", scrollAtBottom);
        if (scrollAtBottom) {
            chatScrollToEnd();
        }
    }, [messages, scrollAtBottom, sending, agentTyping]);

    useEffect(() => {
        // console.log("useEffect2 running, ref", indicatorRefCurrent);
        const callback = (entries: IntersectionObserverEntry[]) => {
            // console.log("useEffect cb entry", entries[0]);
            set_scrollAtBottom(entries[0].isIntersecting);
        };
        const observer = new IntersectionObserver(callback, {
            root: document.querySelector(".messages"),
            rootMargin: "30px", // px before the bottom
            threshold: 1,
        });
        if (indicatorRefCurrent) {
            // console.log("useEffect has indicator", indicatorRefCurrent);
            observer.observe(indicatorRefCurrent);
        } else {
            console.warn("useEffect no indicator, ref", indicatorRef);
        }
        return () => {
            observer.disconnect();
        };
    }, [set_scrollAtBottom, indicatorRefCurrent]); // TODO: find better way to ensure this does not listen to an old element..

    const errorMsg =
        connStatus !== "DownWithError" ? (
            <></>
        ) : (
            <div className="tw-mt-8 tw-w-full tw-rounded tw-border tw-border-solid tw-border-dpj-copper-orange/15 tw-bg-dpj-copper-orange/5 tw-p-4 tw-pb-0">
                <h6>
                    We are sorry.. <br />
                    We encountered an error.
                </h6>
                <ul className="tw-ps-6">
                    <li className="tw-pb-1">Try starting a new chat or..</li>
                    <li className="tw-pb-1">Try refreshing the page or..</li>
                    <li className="tw-pb-1">
                        Contact us through another channel. (link at the bottom
                        of the page.)
                    </li>
                </ul>
                <div className="error-hint tw-ml-auto tw-min-h-4 tw-w-fit tw-pr-1 tw-text-[10px]">
                    <code className="tw-text-gray-400">
                        error: {error?.type}
                    </code>
                </div>
            </div>
        );
    const proactiveMessage =
        proactive && proactive.type === "chat" ? (
            <div data-tags="proactive" className="tw-pr-8">
                <div
                    className={cn(
                        "message-proactive",
                        "tw-w-full tw-bg-gray-100",
                        "tw-mt-2 tw-flex tw-min-h-fit tw-max-w-full tw-flex-col tw-gap-3 tw-overflow-clip tw-rounded tw-p-1 tw-px-2 tw-py-3 tw-pl-[10px] tw-pr-2 tw-pt-[13px] tw-text-sm sm:tw-flex-row",
                    )}
                >
                    <div className="flex tw-flex tw-justify-start">
                        <Avatar />
                        <div className="tw-my-auto tw-h-fit tw-ps-4 tw-font-bold sm:tw-hidden">
                            {proactive.displayName}
                        </div>
                    </div>
                    <div className="tw-flex-1">
                        <div className="tw-hidden tw-font-bold sm:tw-block">
                            {proactive.displayName}
                        </div>
                        <div className="tw-break-words-forced tw-break-words">
                            {proactive.inChatMessage}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;

    return (
        <div className="messages tw-flex tw-h-full tw-w-full tw-overflow-y-auto tw-scroll-smooth tw-border-gray-200 tw-px-5 tw-pt-4">
            <div
                className="messages_div tw-mt-auto tw-flex tw-h-fit tw-w-full tw-flex-col-reverse tw-gap-1 tw-overflow-x-hidden"
                key={messages.length}
            >
                <div
                    className="tw-h-4 tw-w-full tw-bg-transparent"
                    ref={indicatorRef}
                />
                {sending ? <MessageSkeleton variant={"sending"} /> : <></>}
                {errorMsg}
                {agentTyping ? (
                    <MessageSkeleton variant="agentTyping" />
                ) : (
                    <></>
                )}
                {messages.map(MessageNow)}
                {proactiveMessage}
                <div className="tw-w-full tw-p-4 tw-ps-0 tw-text-lg tw-font-bold">
                    {l["Welcome to the DPJ Chat"]}
                </div>
            </div>
        </div>
    );
}

export default Messages;
