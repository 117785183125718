import * as Sentry from "@sentry/react";


// ENVIRONMENT SETUP
const DEFAULT_ENVIRONMENT = "local";
let environment = DEFAULT_ENVIRONMENT;
// @ts-expect-error undefined var
if (typeof dpj_runtime === "string") {
    // @ts-expect-error undefined var
    environment = dpj_runtime;
} else {
    console.warn("dpj_runtime not set, defaulting to '" + DEFAULT_ENVIRONMENT + "'");
}

// COMPATIBILITY FIXES
// Webpack sets this global variable to the window object. A dependency to the sdk relies on this so we set it ourselves before importing anything else.
window.global ||= window;

// SENTRY INIT
Sentry.init({
    dsn: "https://fe7ebcb241265e1379c7a30e8f400cb5@o4507526370623488.ingest.de.sentry.io/4507973491163216",
    environment,
    maxBreadcrumbs: 100,
    // @ts-expect-error undefined var
    debug: import.meta.env.DEV,
    integrations: [
        // NOTE: Check vite.config.ts before activating replayIntegration
        Sentry.replayIntegration({
            unblock: ["#dpj_widget"],
            unmask: ["#dpj_widget"],
            maskAllInputs: false,
        }),
        // disable some handlers to avoid cathcing errors from other sources
        Sentry.globalHandlersIntegration(
            {
                onerror: false,
                onunhandledrejection: false,
            },
        ),
        Sentry.browserApiErrorsIntegration({
            setTimeout: false,
            setInterval: false,
            requestAnimationFrame: false,
            XMLHttpRequest: false,
            eventTarget: false,
        }),
        // Breadcrumbs are used to track the user's journey through the app, I'm diasbling some of them to avoid data to look through when debugging
        Sentry.breadcrumbsIntegration({
            console: false,
            dom: true,
            fetch: false,
            history: true,
            xhr: false,
        }),
    ],
    // Exclude errors from being sent to Sentry...
    // ...if in this list of error messages
    // ignoreErrors: [
    //     "https://goo.gl/LdLk22", // chrome video.play() error, see https://goo.gl/LdLk22 for more information
    //     "AbortError", // I think Chrome throws this error when a fetch request is aborted?
    // ],
    // ...if not from a relevant file
    // beforeSend(event) {
    //     if (!event.environment) {
    //         return null;
    //     }

    //     if (!event.exception ||
    //         !event.exception.values ||
    //         !event.exception.values[0] ||
    //         !event.exception.values[0].stacktrace ||
    //         !event.exception.values[0].stacktrace.frames) {
    //         return event;
    //     }

    //     const isRelevantError = !(event.exception.values[0].stacktrace.frames
    //         .some(frame => {
    //             return frame.filename && !frame.filename.includes("/modules/dpjwidget/");
    //         }))

    //     if (isRelevantError) {
    //         return event;
    //     }
    //     // If it's not a relevant error. Drop the event.
    //     return null;
    // },

    // Capture Replay for 0% of all sessions, (except those manually triggered)
    // plus for 5% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.05,

});

// @ts-expect-error undefined var
DEV: window.Sentry = Sentry;
DEV: console.log("relese:", Sentry.getClient()?.getOptions().release?.slice(0,12) || "unknown");
