import { useEffect, useState } from "react";
import { Avatar } from "@/components/ui/avatar";
import { cn } from "@/lib/utils";
import { Content } from "./Content";
import { Timestamp } from "./Timestamp";
import { DownloadIcon } from "@radix-ui/react-icons";
import { localization as l } from "@/lib/localization";
import urlImgMale from "@/../images/male.webp";
import urlImgFemale from "@/../images/female.webp";
import { Message as TMessage } from "@/lib/types";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import { chatScrollToEnd } from "@/lib/utils";

type Props = {
    chatSDK: OmnichannelChatSDK;
    msg: TMessage;
    latest?: boolean;
};
const avatarImageTable = {
    male: ["Johan"],
    female: ["Jane"],
};

function Message({ chatSDK, msg, latest = false }: Props) {
    const [objectURL, objectURL_set] = useState<string | null>(null);
    useEffect(() => {
        let url = "";
        if (msg.fileMetadata) {
            console.log("downloading file", msg.fileMetadata);
            chatSDK
                .downloadFileAttachment(msg.fileMetadata)
                .then((blob) => {
                    url = URL.createObjectURL(blob);
                    objectURL_set(url);
                    console.log("file downloaded", url);
                })
                .catch((err) => {
                    console.error("err", err);
                    console.error("failed to download file", msg.fileMetadata);
                })
                .finally(() => {
                    // console.log("[Message] download done, scrolling to end?", latest);
                    if (latest) {
                        setTimeout(() => {
                            chatScrollToEnd(); // TODO: might need to scroll on all downloads as they usually change the height of the message list
                        }, 0);
                    }
                });
        }
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [chatSDK, msg, latest]);

    let file: JSX.Element | null = null;
    if (
        msg.fileMetadata &&
        msg.fileMetadata.type.includes("image") &&
        !objectURL
    ) {
        file = (
            <div className="tw-w-full tw-justify-between tw-rounded tw-border-dpj-forest-green/20 tw-px-2 tw-py-2 tw-text-center">
                {l["Loading image preview"]}
            </div>
        );
    } else if (
        msg.fileMetadata &&
        msg.fileMetadata.type.includes("image") &&
        objectURL
    ) {
        file = (
            <div className="tw-rounded tw-border tw-border-solid tw-border-dpj-forest-green/20 tw-bg-white">
                <img
                    src={objectURL}
                    alt={msg.fileMetadata.name}
                    className="tw-p-2 tw-pb-0"
                />
                <a
                    className="tw-flex tw-justify-between tw-rounded-b tw-p-2 !tw-underline hover:tw-bg-dpj-forest-green/20 hover:!tw-text-dpj-forest-green"
                    href={objectURL}
                    download={msg.fileMetadata.name}
                >
                    <DownloadIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0" />
                    <span className="tw-break-words-forced tw-break-words !tw-underline">
                        {msg.fileMetadata.name}
                    </span>
                </a>
            </div>
        );
    } else if (msg.fileMetadata && !objectURL) {
        file = <div>{l["Downloading file"]}</div>;
    } else if (msg.fileMetadata && objectURL) {
        file = (
            <a
                className="tw-flex tw-w-full tw-justify-between tw-rounded tw-border tw-border-solid tw-border-dpj-forest-green/20 tw-p-2 !tw-underline hover:tw-bg-dpj-forest-green/20 hover:!tw-text-dpj-forest-green"
                href={objectURL}
                download={msg.fileMetadata.name}
            >
                <DownloadIcon className="tw-h-6 tw-w-6 tw-flex-shrink-0" />
                <span className="tw-break-words-forced tw-break-words !tw-underline">
                    {msg.fileMetadata.name}
                </span>
            </a>
        );
    }
    if (!msg.tags) {
        throw Error("error, no tags on msg");
    }

    if (msg.tags.includes("agentassignmentready")) {
        return null;
    } else if (msg.tags.includes("system")) {
        return (
            <div key={msg.id} data-tags={msg.tags} className="">
                <div
                    className={cn(
                        "message-system",
                        "tw-mx-auto tw-flex tw-h-fit",
                        {
                            "border tw-rounded tw-border-b tw-border-gray-400":
                                false,
                        },
                        "tw-text-sm",
                    )}
                >
                    <span className="tw-flex">{msg.content}</span>
                </div>
            </div>
        );
    } else if (msg.tags.includes("FromCustomer")) {
        return (
            <div
                key={msg.id}
                data-tags={msg.tags}
                className="tw-max-w-full tw-pl-4 tw-pt-4 sm:tw-pl-8"
            >
                <div
                    className={cn(
                        "message-customer",
                        "tw-ml-auto tw-w-fit tw-bg-dpj-forest-green/15",
                        "tw-mt-2 tw-flex tw-min-h-fit tw-max-w-full tw-flex-col tw-gap-3 tw-overflow-clip tw-rounded tw-p-1 tw-px-2 tw-py-3 tw-pl-[10px] tw-pr-2 tw-pt-[13px] tw-text-sm sm:tw-flex-row",
                    )}
                >
                    <div className="tw-break-words-forced tw-ml-auto tw-w-fit tw-break-words">
                        <Content str={msg.content} />
                        {file ? (
                            <div className="tw-mt-2 tw-w-full tw-text-end">
                                {l["We have recieved your file"]}
                                {file}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Timestamp
                    date={new Date(msg.timestamp)}
                    className="tw-text-end"
                />
            </div>
        );
    } else if (msg.tags.includes("public")) {
        let avatarImage = "";
        if (avatarImageTable.male.includes(msg.sender.displayName)) {
            avatarImage = urlImgMale;
        }
        if (avatarImageTable.female.includes(msg.sender.displayName)) {
            avatarImage = urlImgFemale;
        }

        return (
            <div
                key={msg.id}
                data-tags={msg.tags}
                className="tw-pr-4 sm:tw-pr-8"
            >
                <div
                    className={cn(
                        "message-agent",
                        "tw-w-full tw-bg-gray-100",
                        "tw-mt-2 tw-flex tw-min-h-fit tw-max-w-full tw-flex-col tw-gap-3 tw-overflow-clip tw-rounded tw-p-1 tw-px-2 tw-py-3 tw-pl-[10px] tw-pr-2 tw-pt-[13px] tw-text-sm sm:tw-flex-row",
                    )}
                >
                    <div className="flex tw-flex tw-justify-start">
                        <Avatar className="" src={avatarImage} />
                        <div className="tw-my-auto tw-h-fit tw-ps-4 tw-font-bold sm:tw-hidden">
                            {msg.sender.displayName}
                        </div>
                    </div>
                    <div className="tw-flex-1">
                        <div className="tw-hidden tw-font-bold sm:tw-block">
                            {msg.sender.displayName}
                        </div>
                        <div className="tw-break-words-forced tw-break-words">
                            <Content str={msg.content} />
                        </div>
                        {file}
                    </div>
                </div>
                <Timestamp
                    date={new Date(msg.timestamp)}
                    className="tw-mt-1 tw-text-start"
                />
            </div>
        );
    }

    throw Error("unknown message type");
}

export { Message };
