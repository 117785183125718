import "./init.js";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import Chat from "./Chat";
import { init as initLocalization } from "@/lib/localization";
import "./index.css";
import * as Sentry from "@sentry/react";
import OmnichannelConfig from "@microsoft/omnichannel-chat-sdk/lib/core/OmnichannelConfig.js";
import ChatConfig from "@microsoft/omnichannel-chat-sdk/lib/core/ChatConfig.js";

console.time("full init");
console.time("initialize localization");
initLocalization();
console.timeEnd("initialize localization");

window.addEventListener(
    "load",
    () => {
        run();
    },
    { once: true },
);

// NOTE: (see vite.config.js)
//  - we're dropping `console.*` and `debugger` statements in prod
//  - import.meta.env is a Vite feature
//    - import.meta.env.DEV  |> true on dev
//    - import.meta.env.PROD  |> true on prod
function run() {
    // sdk settings
    const chatSDKConfig = {
        dataMasking: {
            disable: false,
            maskingCharacter: "#",
        },
        telemetry: {
            disable: true,
        },
    };
    // Initialize chat SDK
    const root = document.getElementById("dpj_widget");
    if (!root) {
        console.error("No root element found");
    } else {
        const conf = {} as OmnichannelConfig;
        conf.orgId = root.dataset.orgId || "";
        conf.orgUrl = root.dataset.orgUrl || "";
        conf.widgetId = root.dataset.widgetId || "";
        if (conf.orgId === "" || conf.orgUrl === "" || conf.widgetId === "") {
            console.error("Missing data attributes");
            return;
        }

        const sdk = new OmnichannelChatSDK(conf, chatSDKConfig);
        // @ts-expect-error unsused undefined variable
        DEV: window.ocSdk = sdk;
        console.time("initialize chat sdk");
        sdk.initialize()
            .then((chatConfig: ChatConfig) => {
                // Check if the widget should be shown
                if (
                    chatConfig.LiveWSAndLiveChatEngJoin.OutOfOperatingHours !==
                        "False" ||
                    chatConfig.LiveWSAndLiveChatEngJoin.ShowWidget !== "True"
                ) {
                    console.log(
                        "out of operating hours",
                        chatConfig.LiveWSAndLiveChatEngJoin.OutOfOperatingHours,
                    );
                    console.log(
                        "ShowWidget",
                        chatConfig.LiveWSAndLiveChatEngJoin.ShowWidget,
                    );
                    return;
                }

                // Finally render the chat
                console.time("render chat");
                if (root) {
                    createRoot(root).render(
                        <StrictMode>
                            <Sentry.ErrorBoundary fallback={<></>}>
                                <Chat chatSDK={sdk} />
                            </Sentry.ErrorBoundary>
                        </StrictMode>,
                    );
                }
                console.timeEnd("render chat");
            })
            .catch((error) => {
                console.error("initialize error", error);
                Sentry.captureException(error);
            })
            .finally(() => {
                console.timeEnd("initialize chat sdk");
                console.timeEnd("full init");
            });
    }
}
