import SpeachBubble from "../icons/SpeachBubble";
import { useGlobalStore } from "@/lib/state/globalStore";
import { NotificationDot } from "@/components/ui/notificationDot";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

function Dot(): JSX.Element {
    const set_open = useGlobalStore((state) => state.set_open);
    const proactiveDismiss = useGlobalStore((state) => state.proactiveDismiss);
    const set_allMessagesSeen = useGlobalStore(
        (state) => state.set_allMessagesSeen,
    );

    // duplicate implementation in ProactiveMessage.tsx
    const [shouldNudgeUp, set_shouldNudgeUp] = useState(
        !!document.querySelector(".product-add-to-cart.fixed"),
    );
    useEffect(() => {
        const addToCartButton = document.querySelector(".product-add-to-cart");
        if (!addToCartButton) {
            return;
        }
        set_shouldNudgeUp(addToCartButton.classList.contains("fixed"));
        const config = { attributes: true, childList: false, subtree: false };
        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === "class"
                ) {
                    set_shouldNudgeUp(
                        (mutation.target as Element).classList.contains(
                            "fixed",
                        ),
                    );
                }
            }
        } as MutationCallback;
        const observer = new MutationObserver(callback);
        if (addToCartButton) {
            observer.observe(addToCartButton, config);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    function openChat() {
        try {
            proactiveDismiss();
        } catch (e) {
            console.warn("Error dismissing proactive", e);
        }
        set_open("chat");
        set_allMessagesSeen();
    }

    return (
        <button
            type="button"
            className={cn(
                "tw-fixed tw-bottom-[15px] tw-right-[15px] tw-z-20 tw-h-[60px] tw-w-[60px] tw-rounded-full tw-bg-dpj-forest-green tw-text-sm tw-font-semibold tw-text-white tw-shadow hover:tw-bg-dpj-forest-green/90 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-dpj-forest-green lg:tw-bottom-4 lg:tw-right-4",
                { "!tw-bottom-[100px]": shouldNudgeUp },
            )}
            onClick={openChat}
        >
            <NotificationDot />
            <SpeachBubble size={30} />
        </button>
    );
}

export default Dot;
