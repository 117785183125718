import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

function initials(str: string) {
    try {
        const split = str.split(" ").filter((w) => w !== "");
        if (split.length === 1 && split[0].length >= 2) {
            return (
                // @ts-expect-error undefined?
                split[0].at(0).toUpperCase() + split[0].at(1).toLowerCase()
            );
        } else if (split.length >= 2) {
            return (
                // @ts-expect-error undefined?
                split[0].at(0).toUpperCase() +
                // @ts-expect-error undefined?
                split[split.length - 1].at(0).toUpperCase()
            );
        } else {
            return "dpj";
        }
    } catch (error) {
        console.warn(`failed to create initials (from ${str})\nerr:`, error);
        return "dpj";
    }
};

function chatScrollToEnd() {
    console.assert(
        document.querySelector(".messages_div"),
        "[sdkWrangler]", "no messages element",
    );
    console.assert(
        document.querySelector(".messages_div")?.firstElementChild,
        "[sdkWrangler]", "no first child",
    );
    console.log("[utils]", "scrolling to end");
    document.querySelector(".messages_div")?.firstElementChild?.scrollIntoView();
}

function focusInput() {
    console.log("[utils]", "focusing input");
    console.assert(
        document.getElementById("ChatInput"),
        "[sdkWrangler]", "no ChatInput element",
    );
    document.getElementById("ChatInput")?.focus();
}


// TODO: implement getLocation
// type Location = {
//     status: number;
//     latitude: number;
//     longitude: number;
// }
// // 'https://ipapi.co/json/'
// // 'https://ipinfo.io/json?token=a81faa9437ada6'
// function getLocation(): Promise<Location> {
//     return new Promise((resolve, reject) => {
//         const providers = [
//         ]
//         // - EXECUTION - //
//         // invoke a "random" provider
//         const providerId = new Date().getMilliseconds() % providers.length
//         providers[providerId](providerId)
//     })
// }

export { cn, initials, focusInput, chatScrollToEnd };
