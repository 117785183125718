const LOCALIZATION_ROOT_ID = "dpj_widget__localization";

type Localization = {
    [key in LocStr]: string;
}

type LocStr =
    | "Sending"
    | "Send"
    | "Write a message"
    | "No text"
    | "Connection error"
    | "Chat input"
    | "Send message"
    | "Downloading file"
    | "Loading image preview"
    | "We have recieved your file"
    | "Now"
    | "Less than a minute ago"
    | "A minute ago"
    | "[x] minutes ago"
    | "Do you want to end this chat?"
    | "Confirm end chat"
    | "Go back to the chat"
    | "Yes"
    | "No"
    | "Download transcript"
    | "Chat with DPJ"
    | "Minimize"
    | "Close"
    | "Start chatting"
    | "Connecting to chat"
    | "Welcome! Click to start a chat"
    | "We're sorry, something went wrong"
    | "Ending chat"
    | "Welcome to the DPJ Chat"
    | "Start a new chat"


const localization: Localization = {
    "Sending": "",
    "Send": "",
    "Write a message": "",
    "No text": "",
    "Connection error": "",
    "Chat input": "",
    "Send message": "",
    "Downloading file": "",
    "Loading image preview": "",
    "We have recieved your file": "",
    "Now": "",
    "Less than a minute ago": "",
    "A minute ago": "",
    "[x] minutes ago": "",
    "Do you want to end this chat?": "",
    "Confirm end chat": "",
    "Go back to the chat": "",
    "Yes": "",
    "No": "",
    "Download transcript": "",
    "Chat with DPJ": "",
    "Minimize": "",
    "Close": "",
    "Start chatting": "",
    "Connecting to chat": "",
    "Welcome! Click to start a chat": "",
    "We're sorry, something went wrong": "",
    "Ending chat": "",
    "Welcome to the DPJ Chat": "",
    "Start a new chat": "",
}


function init(): Localization {
    // LOCALIZATION INIT
    const localizationRoot = document.getElementById(LOCALIZATION_ROOT_ID);
    if (!localizationRoot) {
        console.error(
            `Could not find localization root with id: ${LOCALIZATION_ROOT_ID}. Please make sure the root element is present in the DOM as it contains all translatable strings. \nFalling back to English.`,
        );
        for (const key in localization) {
            localization[key as LocStr] = key;
        }
        return localization;
    } else {
        for (const key in localization) {
            const el = localizationRoot.querySelector(`[data-localization-from="${key}"]`);
            if (el?.textContent?.length && el.textContent.length > 0) {
                localization[key as LocStr] = el.textContent;
            } else {
                console.error(`Could not find localization for key: "${key}"\nFalling back to Key.`);
                localization[key as LocStr] = key;
            }
        }
        return localization;
    }
}

export type { Localization };
export { init, localization };
